@import '../_styles/colors';
@import '../_styles/constants';
@import '../_styles/breakpoints';

.home {

  height: 100vh;
  background-color: black;
  background-image: url('./img/bg.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0px;
  
  position: relative;
  overflow: hidden;

  @media screen and (max-width:922px) {
    background-size: cover;
    background-position: center center;
  }

  .filterL {
    position: absolute;
    height: 100%;
    background: #000;
  }

  .filterL {
    top: 0;
    right: 50%;
    width: 301px;
    box-shadow: 76px 4px 250px 300px #000;

    @media screen and (max-width: 1100px) {
      left: 0;
      
    }


    @media screen and (max-width: 768px) {
      width: 70px;
      box-shadow: 20px 1px 150px 200px #000;
    }

    @media screen and (max-width: 514px) {
      width: 50px;

    }
  }

 
  @media screen and (max-width: 768px) {
    padding: 30px;
    justify-content: center;
    align-items: start;
  }

  .left {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  
    @media screen and (max-width: 768px) {
      height: 100%;
      justify-content: space-between;
      width: 100%;
    }

    .bottom {
      text-align: center;

      @media screen and (max-width:514px) {
        font-size: 10px;
      }
    }

    .header {
      background-image: url('./img/logo.png');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
      width: 380px;
      height: 100px;
      

      @media screen and (max-width: 514px) {
        width: 100%;
      }
    }

    .content {
      text-align: center;

      @media screen and (max-width: 514px) {
        width: 100%;
      }

      

      .registerBtn {
        margin-top: 0px;
        margin-bottom: 20px;
        background-color: $primary-color;
        border-radius: 58px;
        padding: 8.5px 50px;

        p {
          font-weight: 800;
          font-size: 14px;
        }
      }

      .inputs {
        display: flex;
        flex-direction: column;

        span {
          text-align: left !important;
        }

        input {
          border-radius: 50px;
          border: none;
          padding: 10px;
        }

        input:focus {
          outline: none;
        }

      }

      .slideRow {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 600;
        }
      }

      .row {
        display: flex;
        align-items: center;
        column-gap: 5px;
        width: 100%;
        justify-content: center;

        .apple,
        .play {
          background-position: center center;
          width: 100px;
          height: 30px;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .apple {
          background-image: url('./img/apple.png');
        }

        .play {
          background-image: url('./img/play.png');
        }
      }
    }
  }
}

.dropdown {
  position: relative;

  .Dropdown-placeholder {
    border-radius: 50px;
    border: none;
    padding: 10px;
    background-color: white;
    color: #000;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    text-align: left;
    z-index: 9;
    position:relative;
  }

  .Dropdown-menu {
    top: 20px;
    padding-top: 20px;
    position: absolute;
    background-color: white;
    width: 100%;
    color: #000;
    font-size: 12px;
    cursor: pointer;
    border-bottom-left-radius:20px;
    border-bottom-right-radius: 20px;
    max-height: 200px;
    overflow-y: scroll;

    .Dropdown-option {
      text-align: left;
      padding: 5px 10px;
      font-weight: 400;

      &:hover {
        background-color: $primary-color;
        color: white;
      }
    }
  }

  .Dropdown-arrow-wrapper {
    position: absolute;
    z-index: 10;
    top: 6px;
    right: 15px;

  }
}