@import '../../_styles/colors';
@import '../../_styles/constants';
@import '../../_styles/breakpoints';

.slider {
    width: 40px;
    height: 20px;
    background-color: #ADB3B8;
    display: flex;
    padding: 2px;
    border-radius: 30px;
    justify-content: flex-start;
    align-items: center;
    

    @media screen and (max-width:514px) {
        
        width: 35px;
        height: 17px;
    }

    .circle {
        height: 18px;
        width: 18px;
        border-radius: 100%;
        background-color: white;
        filter: drop-shadow(0px 3px 4px rgba(70, 71, 72, 0.25));
        stroke-width: 0.5px;
        stroke: #ADB3B8;
        transition: transform 0.3s ease;


        @media screen and (max-width:514px) {
            height: 13px;
            width: 13px;
        }
    }
}

.slider.active {
    background-color: $primary-color;

    .circle {
        transform: translateX(18px); // 40px (width of slider) - 18px (width of circle) - 2px (padding) * 2
    }

    @media screen and (max-width:514px) {
        .circle {
            transform: translateX(17.5px); // 28px (width of slider) - 13px (width of circle) - 2px (padding) * 2
        }
    }
}