@import './_styles/breakpoints';
@import './_styles/colors';
@import './_styles/constants';
@import './_styles/form';
@import './_styles/reset';

html {
  // This value defines the value of '1rem'
  font-size: 10px;
}

html,
body {
  height: auto;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-family: $font-family;
  font-weight: $font-weight;
  background-color: black;
}

h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
}

h2 {
  font-size: 24px;
  font-weight: 200;
  line-height: 1;
  margin-bottom: 15px;

  @media screen and (max-width: 514px) {
    font-size: 18px;
  }
}

h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 10px;

  @media screen and (max-width: 514px) {
    font-size: 16px;
  }
}

h4 {
  font-weight: 300;
  color: $primary-color;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;

  @media screen and (max-width: 514px) {
    font-size: 16px;
  }
}

body {
  font-size: $font-size;
}

p {
  font-size: 12px;

}

span {
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 2px;
}

a {

  color: #3FA9F5;

  &.disabled {
    pointer-events: none;
    opacity: $disabled-opacity;
  }
}