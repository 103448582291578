@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-ExtraLight.woff2') format('woff2'),
        url('font-files/EudoxusSans-ExtraLight.woff') format('woff');
    font-weight: normal;
    font-weight: 200;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-Light.woff2') format('woff2'),
        url('font-files/EudoxusSans-Light.woff') format('woff');
    font-weight: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-Regular.woff2') format('woff2'),
        url('font-files/EudoxusSans-Regular.woff') format('woff');
    font-weight: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-Medium.woff2') format('woff2'),
        url('font-files/EudoxusSans-Medium.woff') format('woff');
    font-weight: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-Bold.woff2') format('woff2'),
        url('font-files/EudoxusSans-Bold.woff') format('woff');
    font-weight: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Eudoxus';
    src: url('font-files/EudoxusSans-ExtraBold.woff2') format('woff2'),
        url('font-files/EudoxusSans-ExtraBold.woff') format('woff');
    font-weight: normal;
    font-weight: 800;
}

$font-size: 2rem;
$font-family: 'Eudoxus';
$font-weight: 200;

$border-radius: 0.8rem;
$disabled-opacity: 0.75;
$navbar-height: 10rem;
$input-height: 4rem;

$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;